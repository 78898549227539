import { useLocation } from "react-router-dom";
import './styles/result.css';
import Behaviour from "./subcomponents/Behaviour";
import Otherinfo from "./subcomponents/Otherinfo";
import AssessmentFinal from "./subcomponents/AssessmentFinal";
import PrintButton from "./subcomponents/PrintButton";

const ResultViewFinal = (props) => {
  const location = useLocation();
  console.log(location.state);
  const admission = location.state.admission;
  const profile = location.state.profile;
  const school = location.state.school;
  const history = location.state.history;
  const behaviour = location.state.behaviour;
  const assessment = location.state.records;
  const grading = location.state.grading;
  const teacher = location.state.teacher;
  const section_id = String(location.state.section_id);
  const session_id = String(location.state.session_id);

  let section_name = '';
  let session_name = '';
  
  const hsec_id = String(history.section_id);
  const hses_id = String(history.session_id);
  
  if (section_id === hsec_id) {
    section_name = history.section_name;
  }
  if (session_id === hses_id) {
    session_name = history.session_name;
  }

  function convertToNumber(variable) {
    let number = parseFloat(variable); // or Number(variable)
    if (isNaN(number)) {
      return 0;
    }
    return number;
  }
  
  function getStudentPencentage(assessment){
    let totalfinal_grade = 0;
    let totalpossible_grade = 0;
    for (let i = 0; i < assessment.length; i++) {
      totalfinal_grade += convertToNumber(assessment[i].final_grade);
    }
    totalpossible_grade = assessment.length * 100;
    return ((totalfinal_grade/totalpossible_grade) * 100).toFixed(2);
  }
  
  const alignstyle = {
    textAlign: "center"
  };

  return (
    <>
      <div className="body">
        <div className="side-bar-part">
          <div className="sidebar">
            <div className="school-logo">
              { school.school_logo == null ? null : <img src={ 'https://early.school/cnd/schools_assets/schools_images/schools_sites_images/'+school.school_logo} width="70" />}
            </div>
    
            <div className="student-image">
              { profile.profile_image == null ? null : <img src={ 'https://early.school/cnd/schools_assets/schools_images/students_passport_images/'+profile.profile_image } width="70" />}
            </div>
    
            <div className="student-name">
                <p className="sess-sect" style={alignstyle} >{profile.first_name} {profile.last_name}</p>
            </div>
    
            <div className="age">
                <p className="student-age" style={alignstyle} >Student Age</p>
                <div className="the-age" style={alignstyle}></div>
            </div>

            <div className="weight">
                <p className="student-weight" style={alignstyle} >Student Weight</p>
                <div className="the-weight" style={alignstyle} ></div>
            </div>
    
            <div className="class">
                <p className="sess-sect" style={alignstyle} >Student Class</p>
                <p className="the-class" style={alignstyle} >{ history.class_name }</p>
            </div>
    
            <div className="addmission-no">
                <p className="sess-sect" style={alignstyle} >Admission No</p>
                <p className="No" style={alignstyle} >{ admission.admission_number}</p>
            </div>
    
            <div className="session-section">
                <p className="sess-sect" style={alignstyle} >Session | Section</p>
                <p className="Outcome-of-Sess-sect" style={alignstyle} >{ history.session_name } | { history.section_name }</p>
            </div>
    
            <div className="date-issue">
                <p className="the-dates" style={alignstyle} >DATE ISSUE</p>
                <p className="date" style={alignstyle} >{ new Date().toISOString().slice(0, 10) }</p>
            </div>
    
            <div className="Result-serials">
                <p className="sess-sect" style={alignstyle} >RESULT SERIAL NO</p>
                <p className="the-serial" style={alignstyle} >123456</p>
            </div>
    
            <div className="TERMS" style={alignstyle} >
                <p className="sess">This Result is issued by and remained the Property of the Issuer
                </p>
            </div>
    
            <div className="qrcode" style={alignstyle} >
                <p>VERIFY RESULT USING QR CODE</p>
               <div className="qrcd"></div> 
            </div>
          </div>
        </div>

        <div className="apart-from-side-bar">
          <div className="header">
  
            <div className="school-name">
              <p className="the-school-name">{school.school_name}</p>
            </div>
        
            <div className="address">
              <p className="school-add">{school.school_address}</p>
              <p className="school-email">{school.school_email}</p>
              <p className="school-web">{school.school_url}</p>
            </div>
          </div>
          <div className="headerss">
            <p className="headers">Academic Report</p>
          </div>
          
          { assessment!==null ? 
            <AssessmentFinal 
              key= {assessment.key}
              data= {assessment}
              gradingdata= {grading}
            />
            : null }
          { behaviour!==null ? 
            <Behaviour 
              key={behaviour.class_id}
              data= {behaviour}
            />
            : null }

          { behaviour!==null ? 
            <Otherinfo 
              key={behaviour.id}
              data= {behaviour}
              teacher= {teacher}
              grading ={grading}
            />
            : null }
          
        </div>
      </div>
      <PrintButton />
      {/*<Link to={'/'+enrollmentid+'/'+sessionid+'/'+classid+'/'+sectionid+'/'+'/'+scratchcardid} className='btn btn-primary center'>NEXT</Link>*/}
    </>
  );
};
export default ResultViewFinal;