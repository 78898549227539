import SubjectScoreFinal from "./SubjectScoreFinal";

const AssessmentFinal = (props) => {
  const data = props.data;
  const gdata = props.gradingdata;
  
  function convertToNumber(variable) {
    let number = parseFloat(variable); // or Number(variable)
    if (isNaN(number)) {
      return 0;
    }
    return number;
  }

  // Function to extract subject names from the data object
  function extractSubjectNames(data) {
    const subjectNames = [];

    // Loop through each term
    for (const term in data) {
      // Loop through each object within the term
      for (const obj of data[term]) {
        // Extract the subject name and add it to the array
        subjectNames.push(obj.subject_name);
      }
    }

    return subjectNames;
  }

  function removeDuplicateSubjects(array){
    return Array.from(new Set(array));
  }

  // Call the function and retrieve the subject names
  const subjectNames = removeDuplicateSubjects(extractSubjectNames(props.data));

  function termArranger(data) {
    let term = {};
    const wordDictionary = {
      first: ["first", "1st"],
      second: ["second", "2nd"],
      third: ["third", "3rd"]
    };
  
    for (const key in data) {
      for (const group in wordDictionary) {
        const words = wordDictionary[group];
        const lowerCaseKey = key.toLowerCase();
  
        for (const word of words) {
          if (lowerCaseKey.includes(word)) {
            term[word] = data[key];
            break; // Exit the innermost loop if a word is found
          }
        }
      }
    }

    return term;
  }
  
  let total_ft_first_grade = 0;
  let total_ft_second_grade = 0;
  let total_ft_third_grade = 0;
  let total_ft_fourth_grade = 0;
  let total_ft_exam_grade = 0;
  let total_ft_final_grade = 0;

  let total_st_first_grade = 0;
  let total_st_second_grade = 0;
  let total_st_third_grade = 0;
  let total_st_fourth_grade = 0;
  let total_st_exam_grade = 0;
  let total_st_final_grade = 0;

  let total_tt_first_grade = 0;
  let total_tt_second_grade = 0;
  let total_tt_third_grade = 0;
  let total_tt_fourth_grade = 0;
  let total_tt_exam_grade = 0;
  let total_tt_final_grade = 0;
  let total_final = 0;

  const assessment = termArranger(props.data);
  
  for (let i = 0; i < subjectNames.length; i++){
    if (assessment['first'] == null) {
      total_ft_first_grade = 0;
      total_ft_second_grade = 0;
      total_ft_third_grade = 0;
      total_ft_fourth_grade = 0;
      total_ft_exam_grade = 0;
      total_ft_final_grade = 0;
      total_final = 0;
    } else {
      for (let j = 0; j < assessment['first'].length; j++){
        if (subjectNames[i] === assessment['first'][j].subject_name) {
          total_ft_first_grade += convertToNumber(assessment['first'][j].first_grade);
          total_ft_second_grade += convertToNumber(assessment['first'][j].second_grade);
          total_ft_third_grade += convertToNumber(assessment['first'][j].third_grade);
          total_ft_fourth_grade += convertToNumber(assessment['first'][j].fourth_grade);
          total_ft_exam_grade += convertToNumber(assessment['first'][j].exam_grade);
          total_ft_final_grade += convertToNumber(assessment['first'][j].final_grade);
          total_final += convertToNumber(assessment['first'][j].final_grade);
        }
      }
    }

    if (assessment['second'] == null) {
      total_st_first_grade = 0;
      total_st_second_grade = 0;
      total_st_third_grade = 0;
      total_st_fourth_grade = 0;
      total_st_exam_grade = 0;
      total_st_final_grade = 0;
      total_final = 0;
    } else {
      for (let k = 0; k < assessment['second'].length; k++){
        if (subjectNames[i] === assessment['second'][k].subject_name) {
          total_st_first_grade += convertToNumber(assessment['second'][k].first_grade);
          total_st_second_grade += convertToNumber(assessment['second'][k].second_grade);
          total_st_third_grade += convertToNumber(assessment['second'][k].third_grade);
          total_st_fourth_grade += convertToNumber(assessment['second'][k].fourth_grade);
          total_st_exam_grade += convertToNumber(assessment['second'][k].exam_grade);
          total_st_final_grade += convertToNumber(assessment['second'][k].final_grade);
          total_final += convertToNumber(assessment['second'][k].final_grade);
        }
      }
    }

    if (assessment['third'] == null) {
      total_tt_first_grade = 0;
      total_tt_second_grade = 0;
      total_tt_third_grade = 0;
      total_tt_fourth_grade = 0;
      total_tt_exam_grade = 0;
      total_tt_final_grade = 0;
      total_final = 0;
    } else {
      for (let l = 0; l < assessment['third'].length; l++){
        if (subjectNames[i] === assessment['third'][l].subject_name){
          total_tt_first_grade += convertToNumber(assessment['third'][l].first_grade);
          total_tt_second_grade += convertToNumber(assessment['third'][l].second_grade);
          total_tt_third_grade += convertToNumber(assessment['third'][l].third_grade);
          total_tt_fourth_grade += convertToNumber(assessment['third'][l].fourth_grade);
          total_tt_exam_grade += convertToNumber(assessment['third'][l].exam_grade);
          total_tt_final_grade += convertToNumber(assessment['third'][l].final_grade);
          total_final += convertToNumber(assessment['third'][l].final_grade);
        }
      }
    }
  }

  const assessmentstyle = {
    width: "200px",
    fontSize: "15px",
    fontWeight: "bold"
  };

  const style = {
    width: "30px",
    textAlign: "center",
    height: "8px",
    fontSize: "12px"
  };

  const subjectstyle = {
    width: "200px", 
    fontSize: "15px"
  };

  const totalstyle = {
    width: "200px",
    fontSize: "15px",
    fontWeight: "bold"
  };

  return (
    <>
      <div className="table">
        <table border="1" className="tboby">
          <thead>
            <tr>
            <th rowSpan="2">SUBJECTS</th>
            <th colSpan="3">CONTINOUS ASSESSMENT</th>
            <th>EXAM</th>
            <th>TOTAL 1st TERM</th>
            <th>TOTAL 2nd TERM</th>
            <th>TOTAL 3rd TERM</th>
            <th>GRAND TOTAL</th>
            <th>PER %.</th>
            <th>GRADE</th>
            <th>REMARKS</th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>1st 20</td>
              <td>2nd 20</td>
              <td>3rd 20</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>  
            </tr>
            
              { !subjectNames ? null :
              subjectNames.map((dt) => 
                <SubjectScoreFinal
                  key={dt}
                  subjectdata={dt}
                  assessmentdata={assessment}
                  gradingdata={gdata}
                />
              )}
            <tr>
              <td style={totalstyle}>TOTAL</td>
              <td>{total_tt_first_grade}</td>
              <td>{total_tt_second_grade}</td>
              <td>{total_tt_third_grade}</td>
              <td>{total_tt_exam_grade}</td>
              <td>{total_ft_final_grade}</td>
              <td>{total_st_final_grade}</td>
              <td>{total_tt_final_grade}</td>
              <td>{total_final}</td>
              <td></td>
              <td></td>
              <td colSpan="2"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default AssessmentFinal;